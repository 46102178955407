import firebase from "firebase/app";
import "firebase/firestore";
// You need to enter your own API keys here like I have shown in the video 
const firebaseConfig = {
  apiKey: "AIzaSyDon2alvK2_VAI_PqZAnDEa4ksV2cpslgU",
  authDomain: "axontica-newsletter.firebaseapp.com",
  projectId: "axontica-newsletter",
  storageBucket: "axontica-newsletter.appspot.com",
  messagingSenderId: "513109066567",
  appId: "1:513109066567:web:ec4052258f7d9a62400262"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export default db;
