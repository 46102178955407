import { useState } from "react";
import "./App.css"
import db from "./firebase";
import firebase from "firebase/app";

import Discord from "./discord.png"
import Instagram from "./instagram.png"

function App() {
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("");
  const inputHandler = (e) => {
    setInput(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (input) {
      console.log(input);
      // add to firebase
      db.collection("emails").add({
        email: input,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setInput("");
      setMessage("Thank you for Subscribing!!!");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };
  return (
    <div className="App">
      <div className='content'>
        <div className='container'>
          <h2>Maintenance</h2>
          <p>
            Axontica is currently under construction. 
            If you want to get news about Axontica and be the first on the new Website
            you can sign up in our newsletter down below.
          </p>
          <div className='form'>
            <form onSubmit={submitHandler}>
              <input type="email" placeholder='Type your email address' onChange={inputHandler} value={input} />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
        {message && <div className='alert'>{message}</div>}
        <div className='other'>
          <div className='bots'>
            <p>Check out our Discord Bots</p>
            <a href='https://bots.axontica.com' target="_blank" rel='noreferrer'>https://bots.axontica.com</a>
          </div>
          <div className='social'>
            <p>Social Media</p>
            <div className='links'>
              <a href='https://discord.gg/4Btnu32CZM' target="_blank" rel='noreferrer'>
                <img src={Discord} alt='' />
              </a>
              <a href='https://instagram.com/axonticadev' target="_blank" rel='noreferrer'>
                <img src={Instagram} alt='' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default App;
